body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

.pm-right {
  float: right;
}

.floating-action-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 40px;
  border-radius: 25%;
  background-color: #007bff;
  color: #fff;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.9);
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.floating-action-button:hover {
  transform: scale(1.3);
}

.task-list {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

span.Competitions {
  font-weight: bold;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: #0040ff83;
}
span.Anglers {
  font-weight: bold;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: #00ff3787;
}
span.Bookings {
  font-weight: bold;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: #00f2ff82;
}
span.Rankings {
  font-weight: bold;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: #fffb0082;
}
span.Results {
  font-weight: bold;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: #ed1f1f84;
}
span.Mobile {
  font-weight: bold;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: #1fedce84;
}

.task-list h2 {
  width: 100%;
  text-align: center;
  display: block; 
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.task-list > div > div {
  margin-bottom: 5px;
}

.task-list > div > div > div {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
}

